import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import Banner from "../components/Banners/Non-gtm"
import Advantages from "../components/Advantages"
import Discounts from "../components/Discounts"
import Conditions from "../components/Conditions"
import WaysToGetCard from "../components/WaysToGetCard"
import Partners from "../components/Partners"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { FormPKW } from "../components/FormPKW"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)
  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  const title = (
    <>
      17% на остаток <br /> по карте Халва
    </>
  )
  const subTitle = <p>Рост ваших сбережений</p>

  return (
    <Layout ligal={ligal} phones={phones} buttonText="получить" noIndex={noIndex}>
      <Banner title={title} subTitle={subTitle} orderNum="1" />
      <Advantages orderNum="2" />
      <Discounts orderNum="3" />
      <Conditions rate={halvaOstatok.value} orderNum="4" />
      <WaysToGetCard orderNum="5" />
      <FormPKW
        longForm
        dataLayerName="shortPersonalForm"
        title="Оформить заявку"
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="6"
        subTitle="Заберите его в ближайшем офисе Совкомбанка"
        emptyForm={false}
      />
      <Partners orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/non-gtm/") {
        url
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
