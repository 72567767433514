import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import BgImg from "./BgImg"

import * as styles from "./nonGtm.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

type Props = {
  title: React.ReactNode
  subTitle?: React.ReactNode
  orderNum?: string
}

const buttonText = "Оформить карту"

export default function Banner({ title, subTitle, orderNum }: Props) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <BgImg alt="bg image" className={styles.img} />
      <Container className={styles.container}>
        <div>
          <div className={styles.text}>
            <h1>{title}</h1>
            <div>{subTitle}</div>
          </div>

          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {buttonText}
          </Button>
        </div>
      </Container>
    </section>
  )
}
